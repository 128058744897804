
import Vue from 'vue'
import store from './store'
import axios from 'axios'
import VueCookies from 'vue-cookies';

import modules from './modules/module';
import widgets from './widgets/widget';
import workers from './workers/worker';

Vue.prototype.$axios = axios.create({
  baseURL: 'https://vevanta.ru/api/',
})

Vue.config.productionTip = false
Vue.use(VueCookies);

let components = {
  'NewBunner': () => import('./components/NewBunner'),
  'SlotFixed': () => import('./components/SlotFixed'),
  'Ipoteka': () => import('./components/Ipoteka'),
  'ManagerItem': () => import('./components/ManagerItem'),
  'SearchFilterHome': () => import('./components/SearchFilterHome'),
  'Consultation': () => import('./components/Consultation'),
  'SlotModal': () => import('./components/SlotModal'),
  'FormPhone': () => import('./components/FormPhone'),
  'FormTwo': () => import('./components/FormTwo'),
  'FormPhone': () => import('./components/FormPhone'),
  'IpotekaCalc': () => import('./components/IpotekaCalc'),
  'Quiz1': () => import('./components/Quiz1'),
  'VideoDesctop': () => import('./components/VideoDesctop'),
  'NavBottom': () => import('./components/NavBottom'),
  'TextOverflow': () => import('./components/TextOverflow'),
  'textOverflows': () => import('./components/TextOverflows'),
  'PhotoReport': () => import('./components/PhotoReport'),
  ...modules,
  ...widgets,
  ...workers
}


let vm = new Vue({
  store,
  el: '#app',
  components
}).$mount()

var degrees2meters = function(lon,lat) {
    var x = lon * 20037508.34 / 180;
    var y = Math.log(Math.tan((90 + lat) * Math.PI / 360)) / (Math.PI / 180);
    y = y * 20037508.34 / 180;
    return [x, y]
  }

  let Y = 7811676.926673358
  let X = 7285224.6323689595
  let a = 6378137
  let b = 6356752.314
  let f = (a-b)/ a
  let e = Math.sqrt(2*f-f^2);
  let eh = e/2;
  let pih = Math.PI / 2;
  let ts = Math.exp(-Y/a)
  let phi = pih-2*Math.atan(ts)
  let i = 0;
  let dphi = 1;

  let con = e*Math.sin(phi)
  dphi = pih-2*Math.atan(ts*( (1-con) / (1+con)^e))-phi
  phi=phi+dphi
  let rLong = X/a
  let rLat = phi
  let Long = rLong*180/Math.PI
  let Lat = rLat*180/Math.PI
  console.log(Long);
  console.log(Lat);
